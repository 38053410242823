function jplayerTmpl() {
  const $templateJplayerHTML = ({ subtitle }) => `
    <div class=" player_control" id="jp_container_1">
      <div class="jp-jplayer"></div>
      <div class="jp-gui">
        <div class="player_bar">
          <!--<div class="vod"><div id="jquery_jplayer_1" class="jp-jplayer"></div></div>-->
          <div class="time_bar jp-progress">
            <div class="time_seek jp-seek-bar">
              <div class="time_line jp-play-bar">
                <div class="time_btn"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="control_bar">
          <a href="javascript:;" class="jp-play">
            <img src="/assets/images/jplayer/btn/btn_play.png" width="24" height="24" alt="재생"/>
          </a>

          <a href="javascript:;" class="jp-pause">
            <img src="/assets/images/jplayer/btn/btn01_stop.png" width="24" height="24" alt="일시정지"/>
          </a>
          <div class="time_s">
            <span class="jp-current-time"></span> |
            <span class="jp-duration"></span>
          </div>
          <ul class="speaker_s">
            <li>
              <a href="javascript:;" class="jp-mute">
                <img src="/assets/images/jplayer/icon/ico_speaker.png" width="24" height="24" alt=""/>
              </a>
            </li>
            <li class="bg_speaker jp-volume-bar">
              <div class="spearker_bg jp-volume-bar-value">
                <div class="spearker_btn"></div>
              </div>
            </li>
          </ul>
          ${subtitle ? `<div class="audio-tit">${subtitle}</div>` : ''}
          <div class="full_size">
            <a href="javascript:;" class="jp-full-screen">
              <img src="/assets/images/jplayer/btn/btn_full.png" width="24" height="24" alt=""/>
            </a>
            <a href="javascript:;" class="jp-restore-screen">
              <img src="/assets/images/jplayer/btn/btn_full.png" width="24" height="24" alt=""/>
            </a>
          </div>
        </div>
      </div>
    </div>
    `;

  return {
    $templateJplayerHTML,
  };
}
