/**
 * Tooltip
 */
function Tooltip() {
  const { actions, props, state, setProps, setState, setTarget, addEvent, removeEvent } = etUI.hooks.useCore(
    {
      type: 'default',
      duration: 0.2,
      height: 'auto',
      transform: {
        scale: {
          x: 1,
          y: 1,
        },
        translate: {
          x: 0,
          y: 0,
        },
        delay: 0,
        easing: 'power4.out',
      },
    },
    {
      state: 'close',
    },
    render,
  );
  const { firstNodeFocusOut, lastNodeFocusOut } = etUI.hooks.useA11yKeyEvent();
  const { mediaQueryAction } = etUI.hooks.useMediaQuery();

  // state 변경 시 랜더 재호출
  const name = 'tooltip';
  let component = {};
  let cleanups = [];

  // 요소관련 변수들
  let $target, $tooltipContainer, $openBtn, $closeBtn, $opendim;
  let tooltipCloseBtn, tooltipTriggerBtn, tooltipDim;
  let focusTrapInstance;
  let tooltipContainerX;
  let bodyWidth;

  /**
   * init
   * @param _$target
   * @param _props
   */
  function init(_$target, _props) {
    if (typeof _$target === 'string') {
      $target = document.querySelector(_$target);
    } else {
      $target = _$target;
    }

    if (!$target) {
      throw Error('Target does not exist.');
    }

    setTarget($target);
    setProps({ ...props, ..._props });

    if ($target.ui) return;
    $target.ui = component;

    setup();
    setEvent();

    $target.setAttribute('data-init', 'true');
  }

  function setup() {
    setupTemplate();
    setupSelector();
    setupElement();
    setupActions();

    // focus trap
    // focus trap
    focusTrapInstance = focusTrap.createFocusTrap($target, {
      escapeDeactivates: props.esc,
      onActivate: actions.focusActivate,
      onDeactivate: actions.focusDeactivate,
    });
  }

  /**
   * update
   * @param _props
   */
  function update(_props) {
    if (_props && etUI.utils.shallowCompare(props, _props) && !$target.getAttribute('data-init')) return;
    destroy();

    setProps({ ...props, ..._props });
    setup();
    setEvent();
  }

  function destroy() {
    etUI.utils.allCleanups(cleanups);
    removeEvent();
    $target.ui = null;
    $target.removeAttribute('data-init');
  }

  // frequency

  function setupTemplate() {}

  function setupSelector() {
    // element
    $tooltipContainer = $target.querySelector('.tooltip-container');
    // selecotr
    tooltipTriggerBtn = '.tooltip-btn-trigger';
    tooltipCloseBtn = '.tooltip-btn-close';
    tooltipDim = $target.querySelector('.dim'); // 0616 dim type 추가
    $openBtn = $target.querySelector(tooltipTriggerBtn);
    $closeBtn = $target.querySelector(tooltipCloseBtn);
    $opendim = tooltipDim; // 0616 dim type tooltipDim 변수에 직접 할당
  }

  function setupElement() {
    // set id
    const id = etUI.utils.getRandomUIID(name);
    const titleId = etUI.utils.getRandomUIID(name + '-tit');

    // a11y
    $target.setAttribute('id', id);
    $target.setAttribute('aria-expanded', 'false');
    $target.setAttribute('aria-controls', titleId);
  }

  function setupActions() {
    actions.open = () => {
      // console.log('open', $target);
      const checkOverflow = () => {
        bodyWidth = $tooltipContainer.getBoundingClientRect().width - 30;
        tooltipContainerX = $tooltipContainer.getBoundingClientRect().x;
        if (tooltipContainerX < 0) {
          $tooltipContainer.classList.add('overflow-left');
        } else if (tooltipContainerX > bodyWidth) {
          $tooltipContainer.classList.add('overflow-right');
        }
      };
      const setAnimation = { duration: 0, display: 'none', opacity: 0 };
      const scale = props.transform.scale.x;
      // mediaQueryAction((context) => {
      //   const { isDesktop, isMobile } = context.conditions;
      //   if (props.type === 'default') {
      //     gsap
      //       .timeline()
      //       .to($tooltipContainer, setAnimation)
      //       .to($tooltipContainer, {
      //         duration: props.duration,
      //         display: 'block',
      //         opacity: 1,
      //         onUpdate: () => {
      //           if (isMobile) {
      //             checkOverflow();
      //           }
      //         },
      //       });
      //   }

      //   if (props.type === 'custom') {
      //     gsap
      //       .timeline()
      //       .to($tooltipContainer, setAnimation)
      //       .to($tooltipContainer, {
      //         duration: props.duration,
      //         scale: 1,
      //         display: 'block',
      //         opacity: 1,
      //         onUpdate: () => {
      //           if (isMobile) {
      //             checkOverflow();
      //           }
      //         },
      //       });
      //   }
      // });
      if (props.type === 'default') {
        gsap
          .timeline()
          .to($tooltipContainer, setAnimation)
          .to($tooltipContainer, {
            duration: props.duration,
            display: 'block',
            opacity: 1,
            onUpdate: () => {
              checkOverflow();
              // if (isMobile) {
              //   checkOverflow();
              // }
            },
          });
      }

      if (props.type === 'custom') {
        gsap
          .timeline()
          .to($tooltipContainer, setAnimation)
          .to($tooltipContainer, {
            duration: props.duration,
            scale: 1,
            display: 'block',
            opacity: 1,
            onUpdate: () => {
              checkOverflow();
              // if (isMobile) {
              //   checkOverflow();
              // }
            },
          });
      }

      $closeBtn && $closeBtn.setAttribute('aria-expanded', 'true');
      $tooltipContainer.setAttribute('aria-hidden', 'false');

      if ($closeBtn) {
        $closeBtn.focus();
      }

      document.body.addEventListener('click', (e) => {
        const { target } = e;
        if (target === $tooltipContainer || target === $openBtn) return;
        if (tooltipDim) tooltipDim.style.display = 'none';
        actions.close();
        $openBtn.classList.remove('on');
      });
    };

    actions.close = () => {
      const scale = props.transform.scale.x;

      gsap.timeline().to($tooltipContainer, {
        duration: props.duration,
        display: 'none',
        opacity: 0,
        onComplete: () => {
          if ($tooltipContainer.classList.contains('overflow-left')) {
            $tooltipContainer.classList.remove('overflow-left');
          } else if ($tooltipContainer.classList.contains('overflow-right')) {
            $tooltipContainer.classList.remove('overflow-right');
          }
        },
      });

      $closeBtn && $closeBtn.setAttribute('aria-expanded', 'false');
      $tooltipContainer.setAttribute('aria-hidden', 'true');

      if (props.type === 'default') {
        gsap.timeline().to($tooltipContainer, { duration: props.duration, display: 'none', opacity: 0 });
      }
      if (props.type === 'custom') {
        gsap.timeline().to($tooltipContainer, { duration: props.duration, scale: scale, display: 'none', opacity: 0 });
      }
    };

    actions.focusActivate = () => {};

    actions.focusDeactivate = () => {
      if (!state.trigger) {
        callback = props.negativeCallback;
      }
      actions.close();
      focusTrapInstance.deactivate();
    };
  }

  function setEvent() {
    window.addEventListener('resize', () => {
      actions.close();
    });
    addEvent('click', tooltipTriggerBtn, function () {
      actions.open();

      $openBtn.classList.add('on');
      // 0616 dim type 추가
      if (tooltipDim) {
        tooltipDim.style.display = 'block';
      }
    });
    if ($closeBtn) {
      cleanups.push(firstNodeFocusOut($closeBtn, actions.close));
      cleanups.push(lastNodeFocusOut($closeBtn, actions.close));
      addEvent('click', tooltipCloseBtn, function () {
        actions.close();

        // 0616 dim type 추가
        tooltipDim.style.display = 'none';
      });
    } else {
      cleanups.push(firstNodeFocusOut($openBtn, actions.open));
      cleanups.push(lastNodeFocusOut($openBtn, actions.close));
    }
  }

  function render() {
    const isShow = state.state === 'open';
    const expanded = $tooltipContainer.getAttribute('aria-expanded') === 'true';
    $tooltipContainer.setAttribute('aria-expanded', !expanded);
    $tooltipContainer.setAttribute('aria-hidden', expanded);

    if (isShow) {
      actions.open();
      focusTrapInstance.activate();
      console.log('2번');
    } else {
      focusTrapInstance.deactivate();
      console.log('2번');
    }
  }

  function open() {
    setState({ state: 'open' });
  }

  function close() {
    setState({ state: 'close' });
  }

  component = {
    core: {
      init,
      destroy,
      removeEvent,
    },

    update,
    open,
    close,
  };

  return component;
}
