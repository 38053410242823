
etUI.utils = {
isArray,
allCleanups,
setProperty,
getProperty,
setStyle,
splitTextMask,
splitTextMaskBlock,
wrapMask,
setDocHeight,
getMediaQueryCondition,
ready,
firstNode,
lastNode,
parseHTML,
setupLenis,
selectorStringToElement,
calculateFixedElementsHeight,
scrollToPosition,
debounce,
throttle,
isMarkerQS,
triggerEvent,
getBlendOpacity,
shallowCompare,
isDeepEqual,
isObject,
getValueFromNestedObject,
reverseString,
getRandomId,
getRandomUIID,
capitalize,
uncapitalize,
addPrefixCamelString,
removePrefixCamelString,
countCharacters
}
              