function useDialog() {
  let $layerWrapBox;

  function createLayerWrap() {
    $layerWrapBox = document.createElement('div');
    $layerWrapBox.classList.add('layer-wrap');
    document.body.appendChild($layerWrapBox);
  }

  const alert = (...opts) => {
    let $layerWrap = document.querySelector('.layer-wrap');
    const dialog = new etUI.components.Dialog();

    if (!$layerWrap) {
      createLayerWrap();
      $layerWrap = $layerWrapBox;
    }

    if (typeof opts[0] === 'string') {
      dialog.core.init($layerWrap, { dialogType: 'alert', message: opts[0], callback: opts[1] });
    } else if (typeof opts[0] === 'object') {
      dialog.core.init($layerWrap, { dialogType: 'alert', ...opts[0] });
    }

    dialog.open();
  };

  const confirm = (...opts) => {
    let $layerWrap = document.querySelector('.layer-wrap');
    const dialog = new etUI.components.Dialog();

    if (!$layerWrap) {
      createLayerWrap();
      $layerWrap = $layerWrapBox;
    }

    if (typeof opts[0] === 'string') {
      dialog.core.init($layerWrap, { dialogType: 'confirm', message: opts[0], positiveCallback: opts[1] });
    } else if (typeof opts[0] === 'object') {
      dialog.core.init($layerWrap, { dialogType: 'confirm', ...opts[0] });
    }

    dialog.open();
  };

  const previewImage = (...opts) => {
    let $layerWrap = document.querySelector('.layer-wrap');
    const dialog = new etUI.components.Dialog();

    if (!$layerWrap) {
      createLayerWrap();
      $layerWrap = $layerWrapBox;
    }

    dialog.core.init($layerWrap, { dialogType: 'previewImage', ...opts[0] });

    dialog.open();
  };

  const youtube = (...opts) => {
    let $layerWrap = document.querySelector('.layer-wrap');
    const dialog = new etUI.components.Dialog();

    if (!$layerWrap) {
      createLayerWrap();
      $layerWrap = $layerWrapBox;
    }

    dialog.core.init($layerWrap, { dialogType: 'youtube', ...opts[0] });

    dialog.open();
  };

  const jplayer = (...opts) => {
    let $layerWrap = document.querySelector('.layer-wrap');
    const dialog = new etUI.components.Dialog();

    if (!$layerWrap) {
      createLayerWrap();
      $layerWrap = $layerWrapBox;
    }

    dialog.core.init($layerWrap, { dialogType: 'jplayer', ...opts[0] });

    const jPlayer = new etUI.components.Jplayer();
    const $target = $layerWrap.querySelector('.component-jplayer');
    jPlayer.core.init($target, opts[0]);

    dialog.open();
  };

  return {
    alert,
    confirm,
    previewImage,
    youtube,
    jplayer,
  };
}
