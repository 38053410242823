function SelectBox() {
  const { actions, props, state, setProps, setState, setTarget, addEvent, removeEvent } = etUI.hooks.useCore(
    {
      type: 'custom',
      label: '',
      default: '',
      items: [],
      selectedIndex: 0,
      transition: 'normal',
      scrollTo: false,
      gsapOption: {},
      state: 'close',
    },
    {},
    render,
  );
  const { $templateCustomHTML, $templateBasicHTML } = etUI.templates.selectBoxTmpl();
  const { useSelectShow } = etUI.hooks.useTransition();

  // constant
  const MARGIN = 20;

  // variable
  const name = 'select';
  // eslint-disable-next-line prefer-const
  let component = {};
  let $target,
    // 요소관련 변수들
    selectLabel,
    selectComboBox,
    selectListBox,
    selectOption,
    timeline;

  function init(_$target, _props) {
    if (typeof _$target === 'string') {
      $target = document.querySelector(_$target);
    } else {
      $target = _$target;
    }

    if (!$target) {
      throw Error('Target does not exist.');
    }

    setTarget($target);
    setProps({ ...props, ..._props });

    if ($target.querySelector('.select-list')) {
      return;
    }

    if ($target.ui) return;
    $target.ui = component;

    setup();
    setEvent();
  }

  function setup() {
    $target.setAttribute('data-init', 'true');
    setupTemplate();

    if (props.type === 'basic') return;

    setupSelector();
    setupElement();
    setupActions();

    // effect
    timeline = useSelectShow($target.querySelector(selectListBox), props.transition, props.gsapOption).timeline;

    // state
    actions[props.state || state.state]?.();
  }

  function update(_props) {
    if (_props && etUI.utils.shallowCompare(props, _props) && !$target.getAttribute('data-init')) return;

    destroy();
    $target.ui = component;
    setProps({ ...props, ..._props });
    setup();
    setEvent();
  }

  function destroy() {
    removeEvent();
    $target.ui = null;
    $target.removeAttribute('data-init');
  }

  // frequency
  function setupTemplate() {
    if (props.items.length < 1) return;
    if (props.type === 'custom') {
      const { selectedIndex } = props;
      const itemListTemp = props.items.map((item) => $templateCustomHTML.items(item)).join('');

      $target.innerHTML = `
        ${props.label && $templateCustomHTML.label(props.label)}
        ${props.default ? $templateCustomHTML.selectBtn(props.default) : $templateCustomHTML.selectBtn(props.items.find((item) => item.value == props.items[selectedIndex].value).text)}
        ${props.items && $templateCustomHTML.itemsWrap(itemListTemp)}
      `;
    } else {
      const selectBtnTemp = $templateBasicHTML.selectBtn(props.default);
      const itemListTemp = props.items.map((item) => $templateBasicHTML.items(item)).join('');

      $target.innerHTML = `
        ${props.label && $templateBasicHTML.label(props.label)}
        ${props.items && $templateBasicHTML.itemsWrap(selectBtnTemp + itemListTemp)}
      `;
    }
  }
  function setupSelector() {
    selectLabel = '.combo-label';
    selectComboBox = '.select-box';
    selectListBox = '.select-options';
    selectOption = '.option';
  }

  function setupElement() {
    // id
    const labelId = etUI.utils.getRandomUIID(name);
    const comboBoxId = etUI.utils.getRandomUIID('combobox');
    const listBoxId = etUI.utils.getRandomUIID('listbox');

    // a11y
    etUI.utils.setProperty($target, selectLabel, 'id', labelId);
    etUI.utils.setProperty($target, selectComboBox, 'id', comboBoxId);
    etUI.utils.setProperty($target, selectComboBox, 'role', 'combobox');
    etUI.utils.setProperty($target, selectComboBox, 'aria-labelledby', labelId);
    etUI.utils.setProperty($target, selectComboBox, 'aria-controls', listBoxId);
    etUI.utils.setProperty($target, selectListBox, 'id', listBoxId);
    etUI.utils.setProperty($target, selectListBox, 'role', 'listbox');
    etUI.utils.setProperty($target, selectListBox, 'aria-labelledby', labelId);
    etUI.utils.setProperty($target, selectListBox, 'tabindex', -1);

    // select property
    const options = $target.querySelectorAll(selectOption);
    options.forEach((el, index) => {
      const optionId = etUI.utils.getRandomUIID('option');

      $target[index] = el;
      el['index'] = index;
      el.setAttribute('id', optionId);
      el.setAttribute('role', 'option');
      el.setAttribute('aria-selected', false);

      props.items[index]?.disabled && el.setAttribute('disabled', '');

      if (!$target['options']) $target['options'] = [];
      $target['options'][index] = el;
    });

    !props.default && selectItem(options[props.selectedIndex]);
  }

  function setupActions() {
    let selectIndex = isNaN($target.selectedIndex) ? -1 : $target.selectedIndex;

    function updateIndex(state) {
      if (!state) return;
      selectIndex = isNaN($target.selectedIndex) ? -1 : $target.selectedIndex;
      updateCurrentClass($target[selectIndex]);
    }

    function keyEventCallback() {
      updateCurrentClass($target[selectIndex]);
      etUI.utils.setProperty($target, selectComboBox, 'aria-activedescendant', $target[selectIndex].id);
      $target.querySelector(`${selectComboBox} :last-child`).textContent = $target[selectIndex].textContent;
    }

    actions.open = () => {
      $target.querySelector(selectComboBox)?.focus();
      openState();
      updateIndex(true);
    };
    actions.close = () => {
      $target.querySelector(`${selectComboBox} :last-child`).textContent = $target['options'][$target.selectedIndex]?.textContent ?? props.default;
      closeState();
    };
    actions.select = () => {
      const currentEl = $target.querySelector('.current');
      selectItem(currentEl);
      closeState();
    };

    actions.first = () => {
      selectIndex = 0;
      keyEventCallback();
    };
    actions.last = () => {
      selectIndex = $target['options'].length - 1;
      keyEventCallback();
    };
    actions.up = () => {
      selectIndex = Math.max(--selectIndex, 0);
      keyEventCallback();
    };
    actions.down = () => {
      selectIndex = Math.min(++selectIndex, $target['options'].length - 1);
      keyEventCallback();
    };

    component.open = actions.open;
    component.close = actions.close;
  }

  function setEvent() {
    if (props.type === 'basic') return;

    // a11y
    const actionList = {
      up: ['ArrowUp'],
      down: ['ArrowDown'],
      first: ['Home', 'PageUp'],
      last: ['End', 'PageDown'],
      close: ['Escape'],
      select: ['Enter', ' '],
    };

    addEvent('blur', selectComboBox, (e) => {
      if (e.relatedTarget?.role === 'listbox') return;
      actions.close();
    });

    addEvent('click', selectComboBox, ({ target }) => {
      const toggleState = state.state === 'open' ? 'close' : 'open';
      actions[toggleState]?.();
    });

    // a11y
    addEvent('keydown', selectComboBox, (e) => {
      if (state.state === 'close') return;

      const { key } = e;
      const action = Object.entries(actionList).find(([_, keys]) => keys.includes(key));

      if (action) {
        e.preventDefault();
        const [actionName] = action;
        actions[actionName]?.();
      }
    });

    addEvent('click', selectListBox, ({ target }) => {
      if (!target.role === 'option') return;
      updateCurrentClass(target);
      actions.select();
    });
  }

  function render() {
    const isOpened = state.state === 'open';

    props.transition && timeline(isOpened);
    checkOpenDir(isOpened);

    etUI.utils.setProperty($target, selectComboBox, 'aria-expanded', isOpened);

    const selectedEl = $target.querySelector('[aria-selected="true"]');
    if (isOpened) etUI.utils.setProperty($target, selectComboBox, 'aria-activedescendant', selectedEl?.id ?? '');
    else etUI.utils.setProperty($target, selectComboBox, 'aria-activedescendant', '');
  }

  // custom
  function checkOpenDir(state) {
    if (!state || props.scrollTo) return; // false이거나 scrollTo 기능 있을 때 - 아래로 열림

    const { height: listHeight } = etUI.hooks.useGetClientRect($target, selectListBox);
    const { height: comboHeight, bottom: comboBottom } = etUI.hooks.useGetClientRect($target, selectComboBox);
    const role = window.innerHeight - MARGIN < comboBottom + listHeight;

    etUI.utils.setStyle($target, selectListBox, 'bottom', role ? comboHeight + 'px' : '');
  }

  // update .current class
  function updateCurrentClass(addClassEl) {
    $target.querySelector('.current')?.classList.remove('current');
    addClassEl?.classList.add('current');
  }

  // select item
  function selectItem(target) {
    const targetOption = target?.closest(selectOption);

    if (!targetOption) return;

    $target.selectedIndex = targetOption['index'];
    $target.value = targetOption.getAttribute('data-value');

    etUI.utils.setProperty($target, '[aria-selected="true"]', 'aria-selected', false);
    targetOption.setAttribute('aria-selected', true);

    updateCurrentClass($target.querySelector('[aria-selected="true"]'));
    $target.querySelector(`${selectComboBox} :last-child`).textContent = targetOption.textContent;
  }

  // select state
  function openState() {
    setState({ state: 'open' });
  }

  function closeState() {
    setState({ state: 'close' });
  }

  component = {
    core: {
      state,
      props,

      init,
      removeEvent,
      destroy,
    },

    update,
    open,
    close,
    selectItem,
  };

  return component;
}
