function dialogTmpl() {
  const $templateHTML = ({ dialogType, type, title, message, positiveText, negativeText }) => `
      <div class="component-dialog">
        <div class="dialog-dimm"></div>
        <div class="dialog-frame">
          <div class="dialog-container">
            <div class="dialog-header">
              ${title ? `<h3 class="dialog-tit">${title}</h3>` : ''}
            </div>
            <div class="dialog-content">
              ${dialogType === 'alert' ? `<span class="${type}">icon</span>` : ''}

              <p class="dialog-info">${message.replace(/\n/g, '<br>')}</p>
            </div>
            <div class="btn-group">
              ${dialogType === 'confirm' ? `<button type="button" class="btn btn-square btn-white dialog-negative">${negativeText}</button>` : ''}
              ${positiveText ? `<button type="button" class="btn dialog-positive btn-primary">${positiveText}</button>` : ''}
            </div>

            ${dialogType === 'alert' ? `<button type="button" class="dialog-close"><span class="hide-txt">팝업 닫기</span></button>` : ''}

          </div>
        </div>
      </div>
    `;

  const $templatePreviewImageHTML = ({ dialogType, images, title }) => `
      <div class="component-dialog dialog-preview-image">
        <div class="dialog-dimm"></div>
        <div class="dialog-frame">
          <div class="dialog-container">
            <div class="dialog-header">
              ${title ? `<h3 class="dialog-tit">${title}</h3>` : ''}
            </div>
            <div class="dialog-content">
              <div class="component-swiper" data-component="swiper">
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper">
                  ${images
                    .map(
                      (image) => `
                    <div class="swiper-slide">
                      <img src="${image.src}" alt="${image.alt}" />
                    </div>
                  `,
                    )
                    .join('')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  const $templateYoutubeHTML = ({ url, title }) => `
      <div class="component-dialog youtube-dialog">
        <div class="dialog-dimm"></div>
        <div class="dialog-frame">
          <div class="dialog-container">
            <div class="dialog-header hide-txt">유튜브 영상</div>
            <div class="dialog-content full">
              <div class="youtube-zone">
                <iframe
                src="https://www.youtube.com/embed/${url}?playsinline=0"
                title="${title}"
                frameborder="0"

                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
              </div>
            </div>
            <button type="button" class="dialog-close">
              <span class="hide-txt">팝업 닫기</span>
            </button>
          </div>
        </div>
      </div>
    `;
  const $templatePopupJplayerHTML = ({ audioTitle, videoTitle, videoChurch01, videoChurch02, videoLink, homepageUrl }) => `
    <div class="component-dialog jplayer-dialog">
      <div class="dialog-dimm"></div>
      <div class="dialog-frame">
        <div class="dialog-container">
          <div class="dialog-header">
              ${audioTitle ? `<div class="audio-tit">${audioTitle}</div>` : ''}
              ${videoTitle ? `<div class="video-tit">${videoTitle}</div>` : ''}
              ${
                videoChurch01 || videoChurch02
                  ? `
              <div class="video-txt">
                  ${videoChurch01 ? `<span>${videoChurch01}</span>` : ''}
                  ${videoChurch02 ? `<span>${videoChurch02}</span>` : ''}
              </div>`
                  : ''
              }
              ${homepageUrl ? `<a class="video-btn" href="${homepageUrl}">${videoLink || ''}</a>` : ''}
          </div>
          <div class="dialog-content full">
            <div class="jplayer-zone">
              <div class="component-jplayer">
              </div>
            </div>
          </div>
          <button type="button" class="dialog-close">
            <span class="hide-txt">팝업 닫기</span>
          </button>
        </div>
      </div>
    </div>
    `;

  return {
    $templateHTML,
    $templatePreviewImageHTML,
    $templateYoutubeHTML,
    $templatePopupJplayerHTML,
  };
}
