
etUI.components = {
Accordion,
Calendar,
Collapse,
Dialog,
Input,
Jplayer,
Lottie,
Modal,
SelectBox,
Skel,
SwiperComp,
Tab,
Tooltip,
Turn
}
              