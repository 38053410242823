/**
 * Input
 */
function Input() {
  const { actions, props, state, setProps, setState, setTarget, addEvent, removeEvent } = etUI.hooks.useCore(
    {
      // props
    },
    {
      // state
    },
    render,
  );

  // constant
  const MARGIN = 20;

  // variable
  const name = 'input';
  // eslint-disable-next-line prefer-const
  let component = {};
  // element, selector
  let $target, $inputTarget;
  let inputType;

  /**
   * init
   * @param _$target
   * @param _props
   */
  function init(_$target, _props) {
    if (typeof _$target === 'string') {
      $target = document.querySelector(_$target);
    } else {
      $target = _$target;
    }

    if (!$target) {
      throw Error('Target does not exist.');
    }

    setTarget($target);
    setProps({ ...props, ..._props });

    if ($target.ui) return;
    $target.ui = component;

    setup();
    setEvent();

    $target.setAttribute('data-init', 'true');
  }

  function setup() {
    // template, selector, element, actions
    setupSelector();
    setupElement();
    setupTemplate(); // element에서 요소를 체크해서 템플릿에 들어가므로 순서가 바뀜
    setupActions();

    // state
    setState({ state: props.state });
  }

  /**
   * update
   * @param _props
   */
  function update(_props) {
    if (_props && etUI.utils.shallowCompare(props, _props) && !$target.getAttribute('data-init')) return;
    destroy();

    setProps({ ...props, ..._props });
    setup();
    setEvent();
  }

  function destroy() {
    removeEvent();
    $target.ui = null;
    $target.removeAttribute('data-init');
  }

  // frequency
  function setupTemplate() {
    const { $templateHTML } = etUI.templates.inputTmpl();
    // $target.innerHTML = ``;
    // etUI.locales[etUI.config.locale.default]
    if (props.clear) {
      $inputTarget.insertAdjacentHTML('afterend', $templateHTML.clearButton());
    }
    if (props.togglePassword) {
      $inputTarget.insertAdjacentHTML('afterend', $templateHTML.togglePassword());
    }
    if (props.loading) {
      $inputTarget.insertAdjacentHTML('afterend', $templateHTML.loading());
    }
  }

  function setupSelector() {}

  function setupElement() {
    // id
    const labelId = etUI.utils.getRandomUIID(name);

    // a11y
    // etUI.utils.setProperty($target, $selectLabel, 'id', labelId);

    $inputTarget = $target.querySelector('input');

    if ($target.querySelector('input[type="file"]')) {
      inputType = 'file';
    } else if ($target.querySelector('input[type="checkbox"]')) {
      inputType = 'checkbox';
    } else if ($target.querySelector('input[type="radio"]')) {
      inputType = 'radio';
    } else if ($target.querySelector('input[type="password"]')) {
      inputType = 'password';
    } else if ($target.querySelector('textarea')) {
      inputType = 'textarea';
      $inputTarget = $target.querySelector('textarea');
    } else {
      inputType = 'text';
    }

    // component custom element
    if (props.count) {
      $target.querySelector('.textarea-count-total').textContent = props.count;
      $target.querySelector('.textarea-count').style.opacity = 1;
    }
  }

  let v = '';

  function setupActions() {
    actions.open = () => {};

    actions.close = () => {};

    actions.checkBytes = (e) => {
      const { target } = e;
      let length = 0;
      if (props.multibyte) {
        length = etUI.utils.countCharacters(target.value);
      } else {
        length = target.value.length;
      }

      if (props.countLimit) {
        if (length > props.count) {
          target.value = v;
        } else {
          v = target.value;
          $target.querySelector('.textarea-count-num').textContent = length;
        }
      } else {
        $target.querySelector('.textarea-count-num').textContent = length;

        if (length > props.count) {
          $target.querySelector('.textarea-count-num').classList.add('over');
        } else {
          $target.querySelector('.textarea-count-num').classList.remove('over');
        }
      }
    };

    actions.showClearButton = (e) => {
      if ($inputTarget.value.length > 0) {
        $target.querySelector('.input-field-btn.clear').style.display = 'block';
      } else {
        $target.querySelector('.input-field-btn.clear').style.display = 'none';
      }
    };

    actions.clearText = ({ target }) => {
      $inputTarget.value = '';
      $inputTarget.focus();
      actions.showClearButton();
    };

    actions.togglePassword = ({ currentTarget }) => {
      if ($inputTarget.type === 'password') {
        $inputTarget.type = 'text';
        currentTarget.querySelector('.password-state').classList.add('show');
      } else {
        $inputTarget.type = 'password';
        currentTarget.querySelector('.password-state').classList.remove('show');
      }
    };
  }

  function setEvent() {
    if (inputType === 'textarea') {
      if (props.count) {
        addEvent('input', 'textarea', actions.checkBytes);
      }
    } else if (inputType === 'text') {
      if (props.clear) {
        addEvent('input', 'input', actions.showClearButton);
        addEvent('click', '.input-field-btn.clear', actions.clearText);
      }
    } else if (inputType === 'password') {
      if (props.togglePassword) {
        addEvent('click', '.input-field-btn.password-state', actions.togglePassword);
      }
    }
  }

  function render() {
    // render
  }

  function getLength() {
    if (inputType.match(/text|textarea|password/g)) {
      return $inputTarget.value.length;
    }
  }

  function getByteLength() {
    if (inputType.match(/text|textarea|password/g)) {
      return etUI.utils.countCharacters($inputTarget.value);
    }
  }

  function updateClearButton() {
    etUI.utils.triggerEvent($inputTarget, 'input');
  }

  function showLoading(bool = true) {
    if (bool) {
      $target.querySelector('.input-field-ico.spinner').style.display = 'block';
    } else {
      $target.querySelector('.input-field-ico.spinner').style.display = 'none';
    }
  }

  component = {
    core: {
      state,
      props,
      init,
      removeEvent,
      destroy,
    },

    // callable
    update,
    getLength,
    getByteLength,
    updateClearButton,
    showLoading,
  };

  return component;
}
