function Jplayer() {
  const { actions, props, state, setProps, setState, setTarget, addEvent, removeEvent } = etUI.hooks.useCore(
    {
      // props
      language: 'ko',
      daysOfWeekHighlighted: [0, 6],
    },
    {
      // state
    },
    render,
  );

  // variable
  const name = 'jplayer';
  // eslint-disable-next-line prefer-const
  let component = {};
  // element, selector
  let $target, $player, $playIcon;

  /**
   * init
   * @param _$target
   * @param _props
   */
  function init(_$target, _props) {
    if (typeof _$target === 'string') {
      // target = jquery 타겟 해도 되는지
      $target = document.querySelector(_$target);
    } else {
      $target = _$target;
    }
    if (!$target) {
      throw Error('Target does not exist.');
    }

    setTarget($target);
    setProps({ ...props, ..._props });

    if ($target.ui) return;
    $target.ui = component;
    setup();
    setEvent();

    $target.setAttribute('data-init', 'true');
  }

  function setup() {
    // template, selector, element, actions
    setupSelector();
    setupTemplate();
    setupElement();
    setupActions();
    const id = etUI.utils.getRandomUIID('jplayer');
    // state
    setState({ state: props.state });
  }

  /**
   * update
   * @param _props
   */
  function update(_props) {
    if (_props && etUI.utils.shallowCompare(props, _props) && !$target.getAttribute('data-init')) return;
    destroy();

    setProps({ ...props, ..._props });
    setup();
    setEvent();
  }

  function destroy() {
    removeEvent();
    $target.ui = null;
    $target.removeAttribute('data-init');
  }

  // frequency
  function setupTemplate() {
    const { $templateJplayerHTML } = etUI.templates.jplayerTmpl();
    $target.innerHTML = $templateJplayerHTML(props);
  }

  function setupSelector() {
    $playIcon = $target?.closest('.video-player-wrap')?.querySelector('.ico-play');
  }

  function setupElement() {
    // id
    const labelId = etUI.utils.getRandomUIID(name);

    // a11y
    // 사용자가 작동후 오토플레이 할 수 있음 인라인인 경우
    // 오토플레이인 경우 음소거 하면 작동 가능
    // 팝업인 경우는 오토플레이 가능함 이미 사용자가 페이지네에서 작동했었으므로
    etUI.utils.setProperty($target, 'id', labelId);
    etUI.utils.setProperty($playIcon, 'style', 'transition: none; filter: drop-shadow(0 2px 6px rgba(0,0,0,0.2))');
    $player = $(`#${labelId} .jp-jplayer`);

    // Audio 일때 클래스 하나 추가
    if (props.file.includes('mp3') || props.file.includes('m4a')) {
      $target.classList.add('audio-player');
    }

    $player.jPlayer({
      ready: function () {
        const options = props.file.includes('mp4')
          ? {
              m4v: `${props.file}`,
              poster: `${props.poster}`,
            }
          : {
              mp3: `${props.file}`,
              m4a: `${props.file}`,
              poster: `${props.poster}`,
            };
        $player.jPlayer('setMedia', {
          ...options,
        });
      },
      solution: 'html',
      size: {
        width: '100%',
        height: '',
      },
      supplied: 'm4v, rtmpv, mp3, m4a',
      wmode: 'window',
      preload: 'none',
      nativeSupport: true,
    });
  }

  function setupActions() {
    actions.open = () => {};
    actions.close = () => {};
    // actions.close = () => {
    //   // 전체 화면 모드인지 확인
    //   if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
    //     // 전체 화면 모드에서 벗어나기
    //     const exitFullScreen = document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen;
    //     if (exitFullScreen) {
    //       exitFullScreen.call(document).catch((err) => {
    //         console.error(err);
    //       });
    //     }
    //   }
    // };
  }

  function setEvent() {
    //  URL이 없거나 솔루션이 안될떄
    $player.bind($.jPlayer.event.error, function (event) {
      switch (event.jPlayer.error.type) {
        case $.jPlayer.error.URL:
          console.log('미디어를 재생할 수 없습니다.');
          break;
        case $.jPlayer.error.NO_SOLUTION:
          console.log('플레이어를 사용할 수 없습니다.');
          break;
      }
    });
    $player.bind($.jPlayer.event.click, function (event) {
      if (event.jPlayer.status.paused) {
        $player.jPlayer('play');
      } else {
        $player.jPlayer('pause');
      }
    });
    $player.bind($.jPlayer.event.play, function (event) {
      // ease back
      if (!$playIcon) return;

      gsap.to($playIcon, 0.5, {
        opacity: 0,
        scale: 0.8,
        ease: 'back.in(2.5)',
        overwrite: true,
        onComplete() {
          $playIcon.style.display = 'none';
        },
      });
    });
    $player.bind($.jPlayer.event.pause, function (event) {
      // ease back
      if (!$playIcon) return;
      $playIcon.style.display = 'block';
      gsap.to($playIcon, 0.5, {
        opacity: 1,
        scale: 1,
        ease: 'back.out(2.5)',
        overwrite: true,
      });
    });
  }

  function render() {
    // render
  }

  component = {
    core: {
      state,
      props,
      init,
      removeEvent,
      destroy,
    },

    // callable
    update,
  };

  return component;
}
