function Turn() {
  const { actions, props, state, setProps, setState, setTarget, addEvent, removeEvent } = etUI.hooks.useCore(
    {
      // props
      language: 'ko',
      daysOfWeekHighlighted: [0, 6],
    },
    {
      // state
    },
    render,
  );

  // variable
  const cleanups = [];
  // eslint-disable-next-line prefer-const
  let component = {};
  // element, selector
  let $target, $jqueryTarget, $navigator, $pageInput, $showPageSingle, $showPageDouble, $targetID;
  // condition
  let _isMobile, _isDesktop, isCorner;
  // data
  let pageValue = 1,
    maxImg;
  let _isIOS = false;

  /**
   * init
   * @param _$target
   * @param _props
   */
  function init(_$target, _props) {
    if (typeof _$target === 'string') {
      // target = jquery 타겟 해도 되는지
      $target = document.querySelector(_$target);
    } else {
      $target = _$target;
    }

    if (!$target) {
      throw Error('Target does not exist.');
    }

    setTarget($target);
    setProps({ ...props, ..._props });

    if ($target.ui) return;
    $targetID = _$target;
    $target.ui = component;
    setup();
    setEvent();

    $target.setAttribute('data-init', 'true');
  }

  function setup() {
    // template, selector, element, actions
    maxImg = $target.querySelectorAll('img').length;
    setupSelector();
    setupTemplate();
    setupElement();
    setupActions();
    // state

    setState({ state: props.state });
  }

  /**
   * update
   * @param _props
   */
  function update(_props) {
    if (_props && etUI.utils.shallowCompare(props, _props) && !$target.getAttribute('data-init')) return;
    destroy();

    setProps({ ...props, ..._props });
    setup();
    setEvent();
  }

  function destroy() {
    removeEvent();
    $target.ui = null;
    $target.removeAttribute('data-init');
    $jqueryTarget.turn('destroy');
    $('.preview-controler-area').find('.drop-down-list *').remove();
    etUI.utils.allCleanups(cleanups);
  }

  // frequency
  function setupTemplate() {
    flipSet($targetID);
  }

  function setupSelector() {
    $navigator = $(`[data-move-btn='${$targetID}']`);
    $pageInput = $target.closest('.modal-content').querySelector('.ui-page-number');
    $showPageSingle = $(`[data-flip-single='${$targetID}']`);
    $showPageDouble = $(`[data-flip-double='${$targetID}']`);
    $jqueryTarget = $($target);
  }

  function setupElement() {}

  function setupActions() {
    actions.open = () => {};
    actions.close = () => {};
  }

  function setEvent() {
    let setId;

    cleanups.push([
      etUI.hooks.useEventListener($pageInput, 'keydown', function (event) {
        const key = event.key || event.keyCode;
        if (key === 'Enter' || key === 13) {
          // 엔터 키가 눌렸을 때 실행할 코드
          let inputValue = parseInt($pageInput.value, 10); // 입력값을 숫자로 변환
          if (inputValue > maxImg) {
            // 입력값이 maxImg보다 크면
            $pageInput.value = maxImg; // $pageInput의 값을 maxImg로 설정
            pageValue = maxImg; // pageValue 변수도 maxImg로 업데이트
          } else if (inputValue === 0) {
            pageValue = 1;
          } else {
            pageValue = inputValue; // 그렇지 않으면 입력값을 pageValue로 설정
          }
          $jqueryTarget.turn('page', pageValue);
        }
      }),
      etUI.hooks.useEventListener($pageInput, 'input', () => {
        // 입력된 값에서 숫자가 아닌 모든 문자를 제거
        let value = event.target.value.replace(/[^0-9]/g, '');

        // 숫자로 변환 후, 0 이하의 값은 1로 설정
        value = Number(value);
        if (value < 0) {
          value = '';
        }

        // 변환된 값을 다시 입력 필드에 설정
        $pageInput.value = value;
      }),
      etUI.hooks.useEventListener($pageInput, 'focusin', () => {
        $pageInput.value = '';
      }),
      etUI.hooks.useEventListener($pageInput, 'blur', () => {
        $pageInput.value = pageValue;
      }),
      etUI.hooks.useEventListener(window, 'resize', () => {
        resize($jqueryTarget);
      }),
      etUI.hooks.useEventListener($target, 'click', (e) => {
        if (isCorner) return;
        let $controler, $otherController;
        if (e.clientX - $jqueryTarget.offset().left > $target.clientWidth / 2) {
          $controler = $jqueryTarget.closest('.page-book-container').find('.btn-box .ui-next-btn');
          $otherController = $jqueryTarget.closest('.page-book-container').find('.btn-box .ui-prev-btn');
        } else {
          $controler = $jqueryTarget.closest('.page-book-container').find('.btn-box .ui-prev-btn');
          $otherController = $jqueryTarget.closest('.page-book-container').find('.btn-box .ui-next-btn');
        }

        if (setId) {
          clearTimeout(setId);
        }
        $controler.click();
        $controler.addClass('visible');
        $otherController.removeClass('visible');

        setId = setTimeout(function () {
          $controler.removeClass('visible');
        }, 1500);
      }),

      etUI.hooks.useEventListener($showPageSingle[0], 'click', () => {
        flipDisplay('single');
        $showPageSingle.attr('aria-selected', 'true');
        $showPageDouble.attr('aria-selected', 'false');
      }),
      etUI.hooks.useEventListener($showPageDouble[0], 'click', () => {
        flipDisplay('double');
        $showPageDouble.attr('aria-selected', 'true');
        $showPageSingle.attr('aria-selected', 'false');
      }),
      etUI.hooks.useEventListener($jqueryTarget[0], 'mousemove', (e) => {
        let $controler, $otherController;
        if (isCorner) {
          isCorner = null;
        }

        if (e.clientX - $jqueryTarget.offset().left > $target.clientWidth / 2) {
          $controler = $jqueryTarget.closest('.page-book-container').find('.btn-box .ui-next-btn');
          $otherController = $jqueryTarget.closest('.page-book-container').find('.btn-box .ui-prev-btn');
        } else {
          $controler = $jqueryTarget.closest('.page-book-container').find('.btn-box .ui-prev-btn');
          $otherController = $jqueryTarget.closest('.page-book-container').find('.btn-box .ui-next-btn');
        }
        if (setId) {
          clearTimeout(setId);
        }
        $controler.addClass('visible');
        $otherController.removeClass('visible');

        setId = setTimeout(function () {
          $controler.removeClass('visible');
        }, 1500);
      }),
    ]);
    $navigator.each((_idx, $this) => {
      cleanups.push(
        etUI.hooks.useEventListener($this, 'click', () => {
          if ($this.classList.contains('ui-prev-btn')) {
            $jqueryTarget.turn('previous');
          } else {
            $jqueryTarget.turn('next');
          }
        }),
      );
    });

    // .on('mousedown touchdown', function () {

    // });
    const { mediaQueryAction } = etUI.hooks.useMediaQuery();
    mediaQueryAction((context) => {
      const { isDesktop, isMobile } = context.conditions;
      _isDesktop = isDesktop;
      _isMobile = isMobile;
    });
  }

  function render() {
    // render
  }

  function flipSet(_el) {
    let _display;
    if (window.innerWidth < 1024) {
      _display = 'single';
    } else {
      _display = 'double';
    }

    if (!$jqueryTarget.length || (!$jqueryTarget.find('img').length && !$jqueryTarget.find('.video-box').length)) {
      return;
    }
    if ($jqueryTarget.find('img').length > 0) {
      _display = typeof $jqueryTarget.data('display') !== 'undefined' ? $jqueryTarget.data('display') : _display;
      const _isStartPage = typeof $jqueryTarget.data('start') !== 'undefined' ? $jqueryTarget.data('start') : 1;
      const _pageSize = $jqueryTarget.find('>div').length;

      if (_display == 'single') {
        $showPageSingle.addClass('on');
      } else {
        $showPageDouble.addClass('on');
      }

      $('[data-current="' + _el + '"]  .ui-total').text(_pageSize);
      $jqueryTarget.turn({
        display: _display,
        page: _isStartPage,
        acceleration: false,
        duration: 1000,
        width: sizeCheck(_el).width,
        height: sizeCheck(_el).height,
        elevation: 50,
        when: {
          turning: function (evt, _page, $obj) {
            if ($jqueryTarget.data('start') !== 'undefined' && _page == _isStartPage - 1) {
              evt.preventDefault();
            }
            if ($jqueryTarget.data('start') !== undefined) {
              const _textPage = _page - 2 > 0 ? _page - 2 : 1;
              $('[data-current="' + _el + '"] .ui-current').text(_textPage);
            } else {
              $('[data-current="' + _el + '"] .ui-current').text(_page);
            }
            $('.ui-page-number').val(_page);
          },
          turned: function (evt, _page, $obj) {},
          start: function (event, pageObject, corner) {
            isCorner = corner;
            if ($jqueryTarget.data('start') !== 'string' && pageObject.page == _isStartPage) {
              if (corner == 'tl' || corner == 'bl' || _pageSize == 1) {
                event.preventDefault();
              }
            }
          },
        },
        easeFunction: 'linear',
      });
    }

    setTimeout(function () {
      if ($jqueryTarget.find('img').length > 0) {
        resize(_el);
        $target.closest('.preview-popup-container').classList.add('init-done');
        if (_display === 'double') {
          $showPageDouble.attr('aria-selected', 'true');
        } else {
          $showPageSingle.attr('aria-selected', 'true');
        }
      }
    }, 200);
  }

  function resize() {
    let _currentDisplay;
    // const _defaultDisplay = $jqueryTarget.turn('display');
    if (window.innerWidth < 1024) {
      _currentDisplay = 'single';
    } else {
      _currentDisplay = 'double';
    }
    flipDisplay(_currentDisplay);
  }

  function flipDisplay(_display) {
    if ($(window).outerWidth() > $(window).outerHeight() && _isMobile) {
      $jqueryTarget.css({
        width: 'auto',
        height: '100%',
      });
    } else if ($(window).outerWidth() < $(window).outerHeight() && _isMobile) {
      $jqueryTarget.css({
        width: '100%',
        height: 'auto',
      });
    } else if (_isDesktop) {
      $jqueryTarget.css({
        width: $jqueryTarget.find('img').eq(0)[0].width,
        height: $jqueryTarget.find('img').eq(0)[0].naturalHeight,
      });
    }
    $jqueryTarget.turn('display', _display);

    if (_display == 'single') {
      $jqueryTarget.turn('size', sizeCheck($jqueryTarget).width, sizeCheck($jqueryTarget).height);
      $showPageDouble.removeClass('on');
      $showPageSingle.addClass('on');
      $showPageDouble.attr('aria-selected', 'false');
      $showPageSingle.attr('aria-selected', 'true');
    } else if (_display == 'double') {
      $jqueryTarget.turn('size', sizeCheck($jqueryTarget).width * 2, sizeCheck($jqueryTarget).height);
      $showPageSingle.removeClass('on');
      $showPageDouble.addClass('on');
      $showPageDouble.attr('aria-selected', 'true');
      $showPageSingle.attr('aria-selected', 'false');
    }
  }

  function sizeCheck() {
    const _windowW = $(window).outerWidth(),
      _windowH = $(window).outerHeight();
    if (_isIOS == true) {
      (_windowW = $jqueryTarget.closest('body').outerWidth()), (_windowH = $jqueryTarget.closest('body').outerHeight());
    }

    let _width = $jqueryTarget.find('img:visible').eq(0)[0].width;
    let _height = $jqueryTarget.find('img:visible').eq(0)[0].clientHeight;
    _width = _width > _windowW ? _windowW : _width;
    if (_isMobile) {
      _height = $jqueryTarget.find('img:visible').eq(0)[0].clientHeight;
      _height = _height > _windowH - $('.preview-popup-header').outerHeight() ? _windowH - $('.preview-popup-header').outerHeight() : _height;
    }
    return {
      width: _width,
      height: _height,
    };
  }

  component = {
    core: {
      state,
      props,
      init,
      removeEvent,
      destroy,
    },
    // callable
    update,
  };

  return component;
}
