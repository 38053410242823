// init js
function initUI() {
  const { mediaQueryAction } = etUI.hooks.useMediaQuery();
  const componentList = [
    {
      selector: '.component-input',
      fn: etUI.components.Input,
    },
    {
      selector: '.component-modal',
      fn: etUI.components.Modal,
    },
    {
      selector: '.component-collapse',
      fn: etUI.components.Collapse,
    },
    {
      selector: '.component-accordion',
      fn: etUI.components.Accordion,
    },
    {
      selector: '.component-tooltip',
      fn: etUI.components.Tooltip,
    },
    {
      selector: '.component-tab',
      fn: etUI.components.Tab,
    },
    {
      selector: '.component-select',
      fn: etUI.components.SelectBox,
    },
    {
      selector: '.component-swiper',
      fn: etUI.components.SwiperComp,
    },
    {
      selector: '.component-lottie',
      fn: etUI.components.Lottie,
    },
    {
      selector: '.component-calendar',
      fn: etUI.components.Calendar,
    },
    {
      selector: '.component-jplayer',
      fn: etUI.components.Jplayer,
    },
  ];

  mediaQueryAction((context) => {
    const { isDesktop, isMobile } = context.conditions;

    componentList.forEach(({ selector, fn }) => {
      document.querySelectorAll(selector).forEach((el) => {
        if (el.dataset.desktopOnly) {
          if (isDesktop) {
            const component = new fn();
            component.core.init(el, {}, selector);
          } else {
            if (el.ui) {
              const swiperInstance = el.ui.getSwiperInstance();
              swiperInstance && swiperInstance.destroy(true, true);
              el.ui = null;
            }
          }
          return;
        }

        if (el.dataset.init) {
          return;
        }

        const component = new fn();
        component.core.init(el, {}, selector);
      });
    });

    // 커스텀 아코디언
    // 개발에서 비동기로 붙였을 때 initUI 호출해서 기능 동작되게 common.js에서 옮김
    // 나중에 수정 필요 ?
    if (!document.querySelector('.ui-custom-accordion')) return;
    const $customAccordions = document.querySelectorAll('.ui-custom-accordion');

    $customAccordions.forEach(($customAccordion) => {
      if ($customAccordion.dataset.init) return;
      $customAccordion.dataset.init = true;

      const amountListWraps = $customAccordion.querySelectorAll('.list-items');
      amountListWraps.forEach((listItem) => {
        const listWrap = listItem.querySelector('.amount-list');
        const list = listItem.querySelectorAll('li');
        const listLength = list.length;
        const listButton = listItem.querySelector('.btn-accordion');

        if (listLength <= 1) {
          listButton.style.opacity = 0;
          listButton.style.visibility = 'hidden';
        }

        if (listLength >= 3) {
          listItem.classList.add('list-hide');
          listButton.style.visibility = 'visible';
          if (isDesktop) {
            gsap.set(listWrap, {
              height: '5.9rem',
            });
          } else {
            gsap.set(listWrap, {
              height: '17.3rem',
            });
          }
        }

        etUI.hooks.useEventListener(listButton, 'click', function () {
          if (isDesktop) {
            if (listItem.classList.contains('list-hide')) {
              gsap.to(listWrap, {
                height: 'auto',
                duration: 0.3,
                ease: 'power4.out',
              });
            } else {
              gsap.to(listWrap, {
                height: '5.9rem',
                duration: 0.3,
                ease: 'power4.out',
              });
            }
          } else {
            if (listItem.classList.contains('list-hide')) {
              gsap.to(listWrap, {
                height: 'auto',
                duration: 0.3,
                ease: 'power4.out',
              });
            } else {
              gsap.to(listWrap, {
                height: '17.3rem',
                duration: 0.3,
                ease: 'power4.out',
              });
            }
          }

          listItem.classList.toggle('list-hide');
          listItem.classList.toggle('list-show');
        });
      });
    });
  });

  etUI.dialog = etUI.hooks.useDialog();

  etUI.utils.setDocHeight();
  window.addEventListener(
    'resize',
    etUI.utils.throttle(() => {
      etUI.utils.setDocHeight();
    }, 100),
  );

  if (etUI.config.lenis.enable) {
    etUI.utils.setupLenis();
  }

  /*
  const mediaQueryList = window.matchMedia('(max-width: 600px)')
  console.log('mediaQueryList', mediaQueryList.matches);

  const changeHandler = (e) => {
    console.log('changed!')
  }

  mediaQueryList.addEventListener('change', changeHandler)
  */
  return {};
}

etUI.initUI = initUI;

(function autoInit() {
  const $scriptBlock = document.querySelector('[data-init]');
  if ($scriptBlock) {
    document.addEventListener('DOMContentLoaded', function () {
      initUI();
    });
  }
})();
